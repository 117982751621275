import { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { API_CONNECTION, CLR_BG1, CLR_BG3, CLR_GREEN, CLR_LT1 } from "../app/constants"
import { useSelector } from "react-redux"

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${CLR_BG3};
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${CLR_BG1};
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`
const Input = styled.input`
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${CLR_LT1};
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 1px solid ${CLR_GREEN} !important;
  }
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${CLR_LT1};
  font-size: 1rem;
  outline: none;
  resize: vertical;
  transition: all 0.2s ease;
  height: 7rem;
  &:focus {
    border: 1px solid ${CLR_GREEN} !important;
  }
`
const Button = styled.button`
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: ${CLR_GREEN};
  color: white;
  border-radius: 8px;
`

const NotificationsForm = () => {
  const {currentUser} = useSelector(state => state.user)
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [loading, setLoading] = useState(false)

  const submitForm = e => {
    e.preventDefault()

    setLoading(true)
    axios
      .post(
        `${API_CONNECTION}/api/notifications/send`,
        {
          title: title,
          body: body,
          data: {},
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
        setTitle("")
        setBody("")
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Title>Send Notifications</Title>
      <Form onSubmit={submitForm}>
        <Input
          required
          placeholder="title"
          type="text"
          onChange={e => {
            setTitle(e.target.value)
          }}
          value={title}
        />
        <TextArea
          required
          placeholder="body"
          type="text"
          onChange={e => {
            setBody(e.target.value)
          }}
          value={body}
        />
        {loading ? (
          <div>loading...</div>
        ) : (
          <Button type="submit">Submit</Button>
        )}
      </Form>
    </Container>
  )
}

export default NotificationsForm
