import styled from "styled-components"
import { CLR_BG1, CLR_BG3 } from "../app/constants"
import BanUser from "./BanUser"
import { mobile } from "../app/responsive"
import UnbanUser from "./UnbanUser"
import VerifyUser from "./VerifyUser"
import GetInvoice from "./GetInvoice"
import GetDCD from "./GetDCD"
import OrderSMS from "./OrderSMS"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  background-color: ${CLR_BG3};
  border-radius: 15px;
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${CLR_BG1};
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  ${mobile({ flexWrap: "wrap" })}
`

const QuickActions = () => {
  return (
    <Container>
      <Title>Quick Actions</Title>
      <Wrapper>
        <GetInvoice />
        <GetDCD />
        <BanUser />
        <UnbanUser />
        <VerifyUser />
        <OrderSMS />
      </Wrapper>
    </Container>
  )
}

export default QuickActions
