import styled from "styled-components"
import ReportsHeader from "../components/ReportsHeader"
import { useState } from "react"
import ReportsChoices from "../components/ReportsChoices"
import { useSelector } from "react-redux"
import {
  handleCustomeReport,
  handleReportsPageReport,
} from "../app/helpers/customeReport"
import ExportOptionsModal from "../components/ExportOptionsModal"

const getReportData = (data, choice, customeOptions) => {
  let temp
  switch (choice) {
    case "spw": {
      temp = handleCustomeReport(data, ["stc-web", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "spa": {
      temp = handleCustomeReport(data, ["stc-app", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "cw": {
      temp = handleCustomeReport(data, ["card-web", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "ca": {
      temp = handleCustomeReport(data, ["card-app", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "apw": {
      temp = handleCustomeReport(data, ["apple-web", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "apa": {
      temp = handleCustomeReport(data, ["apple-app", "approved"])
      handleReportsPageReport(temp)
      break
    }
    case "aw": {
      temp = handleCustomeReport(data, [
        "stc-web",
        "card-web",
        "apple-web",
        "approved",
      ])
      handleReportsPageReport(temp)
      break
    }
    case "aa": {
      temp = handleCustomeReport(data, [
        "stc-app",
        "card-app",
        "apple-app",
        "approved",
      ])
      handleReportsPageReport(temp)
      break
    }
    case "dw": {
      temp = handleCustomeReport(data, [
        "stc-web",
        "card-web",
        "apple-web",
        "declined",
      ])
      handleReportsPageReport(temp)
      break
    }
    case "da": {
      temp = handleCustomeReport(data, [
        "stc-app",
        "card-app",
        "apple-app",
        "declined",
      ])
      handleReportsPageReport(temp)
      break
    }
    case "custome": {
      temp = handleCustomeReport(data, customeOptions)
      handleReportsPageReport(temp)
      break
    }

    default:
      break
  }

  return temp.map(el => ({
    trackId: el.trackId,
    total: el.total,
    timestamp: el.createdAt,
    payment: el.payment,
    status: el.status === 1 ? "Approved" : "Declined",
    plateform: el.trackId.length === 10 ? "App" : "Web",
  }))
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const ReportsPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const submitChoice = (choice, title, customeOptions) => {
    console.log(choice)
    setLoading(true)
    getReportData(data, choice, customeOptions)
    setLoading(false)
    return
  }

  if (currentUser.access === "support" || currentUser.access === "blog") return <></>
  if (loading) return <div>Loading...</div>;
  return (
    <Container>
      <ReportsHeader setData={setData} submitChoice={submitChoice} />
      {data && (
        <>
          <ExportOptionsModal submitChoice={submitChoice} />
          <ReportsChoices loading={loading} submitChoice={submitChoice} />
        </>
      )}
    </Container>
  )
}

export default ReportsPage
