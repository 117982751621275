import { useState } from "react"
import styled from "styled-components"
import { CLR_BG2, CLR_LT1, CLR_PR1 } from "../app/constants"
import { mobile } from "../app/responsive"

const Container = styled.div`
  min-width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  height: 25rem;
  background-color: white;
  border-radius: 15px;
  border: 1px solid ${CLR_LT1};
  ${mobile({ minWidth: "unset", width: "calc(50% - 0.5rem)", height: "unset", aspectRatio: "1/1" })}
`
const Title = styled.div`
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${CLR_BG2};
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${CLR_LT1};
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border-bottom: 1px solid ${CLR_PR1};
  }
`
const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  background-color: ${CLR_PR1};
  color: white;
  border-radius: 50px;
`

const QuickForm = ({ title, placeholder, submitForm, button }) => {
  const [value, setValue] = useState("")

  return (
    <Container>
      <Title>{title}</Title>
      <Form onSubmit={e => submitForm(e, value)}>
        <Input
          placeholder={placeholder}
          required
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <Button type="submit" style={{background: button.color}}>{button.text}</Button>
      </Form>
    </Container>
  )
}

export default QuickForm
