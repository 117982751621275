import QuickForm from "../constants/QuickForm"
import { API_CONNECTION, CLR_PR1 } from "../app/constants"
import axios from "axios"
import { useSelector } from "react-redux"

const GetInvoice = () => {
  const { currentUser } = useSelector(state => state.user)

  const submitForm = (e, value) => {
    e.preventDefault()

    axios
      .post(
        `${API_CONNECTION}/api/operations/orderDCD`,
        {
          trackId: value,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
          responseType: "arraybuffer",
        }
      )
      .then(res => {
        // Convert the binary data to a Blob
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })

        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(pdfBlob)

        // Open the PDF file in a new window/tab
        window.open(pdfUrl)
      })
      .catch(e => alert(e.response.status))
  }

  return (
    <QuickForm
      title="Get Order DCD"
      placeholder="TrackId"
      submitForm={submitForm}
      button={{ text: "Show", color: CLR_PR1 }}
    />
  )
}

export default GetInvoice
