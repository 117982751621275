import styled from "styled-components"
import { API_CONNECTION, CLR_PR1 } from "../app/constants"
import { mobile } from "../app/responsive"
import { useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"

const TYPES = {
  product: "I didn't receive a product",
  order: "I didn't receive an order",
  htu: "I can't use a product",
  account: "I have a problem with my account",
  suggestion: "I have a suggestion",
  complaint: "I have a complaint",
  other: "Other",
}

const Container = styled.details`
  width: 100%;
  background-color: white;
`
const Header = styled.summary`
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 1rem;
  padding: 1rem;
  ${mobile({ padding: ".5rem" })}
`
const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid gainsboro;
`
const Title = styled.div`
  font-weight: 700;
`
const Sub = styled.span`
  font-weight: 600;
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid gainsboro;
  outline: none;
  resize: vertical;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 2px solid ${CLR_PR1} !important;
  }
`
const Button = styled.button`
  width: fit-content;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  border-radius: 8px;
  font-weight: 600;
`

const TicketBody = ({ ticket }) => {
  const { currentUser } = useSelector(state => state.user)
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const closeTicket = () => {
    setLoading(true)
    axios
      .post(
        `${API_CONNECTION}/api/setters/ticket/close`,
        { id: ticket.id, message: message },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Header>Ticket Id: {ticket.id}</Header>
      <Wrapper>
        <Section>
          <Title>{TYPES[ticket.type]}</Title>
        </Section>
        <Section>
          <div>By {ticket.mode}</div>
          <div>{new Date(ticket.createdAt).toLocaleString()}</div>
        </Section>
        <Section>{ticket.desc}</Section>
        <Section>
          <div><Sub>User Id:</Sub> {ticket.userId}</div>
          <div><Sub>Order Id:</Sub> {ticket.orderId}</div>
        </Section>
        <Section>
          <div><Sub>User Email:</Sub> {ticket.userEmail}</div>
          <div><Sub>User Mobile:</Sub> {ticket.userMobile}</div>
        </Section>
        <TextArea
          placeholder="Reply (Optional)"
          type="text"
          onChange={e => {
            setMessage(e.target.value)
          }}
          value={message}
        />
        {loading ? (
          <>Loading...</>
        ) : (
          <Button onClick={closeTicket}>Close Ticket</Button>
        )}
      </Wrapper>
    </Container>
  )
}

export default TicketBody
