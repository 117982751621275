import React from 'react'
import styled from 'styled-components'
import LoginForm from '../components/LoginForm'
import LoginHeader from '../components/LoginHeader'
import { CLR_BG1 } from '../app/constants'
import { mobile } from '../app/responsive'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7%;
  padding: 1rem;
  background-color: ${CLR_BG1};
  ${mobile({flexDirection: "column"})}
`

const LoginPage = () => {
  return (
    <Container>
      <LoginForm />
      <LoginHeader />
    </Container>
  )
}

export default LoginPage