import axios from "axios"
import { useState } from "react"
import { API_CONNECTION } from "../app/constants"
import { useSelector } from "react-redux"
import MainForm from "../constants/MainForm"
import MainInfo from "../constants/MainInfo"

const TempsList = () => {
  const { currentUser } = useSelector(state => state.user)
  const [data, setData] = useState(null)

  const submitForm = (e, value) => {
    e.preventDefault()

    axios
      .post(
        `${API_CONNECTION}/api/getters/temps`,
        {
          email: value,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        setData(res.data)
      })
      .catch(e => alert(e.response.status))
  }

  return (
    <>
      <MainForm
        title="Get Temps By Email"
        placeholder="User Email"
        submitForm={submitForm}
      />
      {data && <MainInfo data={data} />}
    </>
  )
}

export default TempsList
