import axios from "axios"
import { API_CONNECTION, CLR_PR1 } from "../app/constants"
import { useSelector } from "react-redux"
import QuickForm from "../constants/QuickForm"

const VerifyUser = () => {
  const { currentUser } = useSelector(state => state.user)

  const submitForm = (e, value) => {
    e.preventDefault()

    axios
      .post(
        `${API_CONNECTION}/api/setters/user/verify`,
        {
          mobile: value,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
      })
      .catch(e => alert(e.response.status))
  }

  return (
    <QuickForm
      title="Verify User"
      placeholder="User's Mobile"
      submitForm={submitForm}
      button={{text: "Verify", color: CLR_PR1}}
    />
  )
}

export default VerifyUser
