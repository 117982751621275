import styled from 'styled-components'
import { mobile } from '../app/responsive'

const Container = styled.img`
  width: 30%;
  ${mobile({width: "75%"})}
`

const LoginHeader = () => {
  return <Container src="login.png" alt="login" />
}

export default LoginHeader