import styled from "styled-components"
import ReportChoice from "./ReportChoice"
import { mobile } from "../app/responsive"

const CHOICES = [
  { id: "spw", title: "STC Pay (Web)", img: "stcpay" },
  { id: "spa", title: "STC Pay (App)", img: "stcpay" },
  { id: "cw", title: "CARD (Web)", img: "card" },
  { id: "ca", title: "CARD (App)", img: "card" },
  { id: "apw", title: "ApplePay (Web)", img: "applepay" },
  { id: "apa", title: "ApplePay (App)", img: "applepay" },
  { id: "aw", title: "Approved (Web)", img: "approved" },
  { id: "aa", title: "Approved (App)", img: "approved" },
  { id: "dw", title: "Declined (Web)", img: "declined" },
  { id: "da", title: "Declined (App)", img: "declined" },
]

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  ${mobile({ padding: "1rem" })}
`

const ReportsChoices = ({ loading, submitChoice }) => {
  return (
    <Container>
      {CHOICES.map((el, i) => (
        <ReportChoice
          key={i}
          id={el.id}
          title={el.title}
          img={el.img}
          loading={loading}
          submitChoice={submitChoice}
        />
      ))}
    </Container>
  )
}

export default ReportsChoices
