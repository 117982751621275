import styled from "styled-components"
import { CLR_BG3, CLR_GREEN, CLR_LT1, CLR_PR1 } from "../app/constants"
import { useState } from "react"
import { mobile } from "../app/responsive"

const Container = styled.div`
  width: calc(${100 / 3}% - ${2 / 3}rem);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${CLR_BG3};
  border-radius: 8px;
  gap: 2rem;
  ${mobile({ width: "100%" })}
`
const Section = styled.div`
  width: 100%;
`
const Title = styled.div`
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
`
const Image = styled.img`
  width: 25%;
`
const Export = styled.div`
  padding: 0.5rem;
  border-radius: 25px;
  border: 1px solid ${CLR_LT1};
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
`
const ExportOption = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 25px;
  background-color: ${props => (props.selected ? CLR_PR1 : "unset")};
  color: ${props => (props.selected ? "white" : "black")};
  cursor: pointer;
`
const ExportButton = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${CLR_GREEN};
  text-decoration: underline;
`

const ReportChoice = ({ id, title, img, loading, submitChoice }) => {
  const [option, setOption] = useState("op")

  return (
    <Container>
      <Title>{title}</Title>
      <Section>
        <Image src={`${img}.png`} alt={title} />
      </Section>
      <Section>
        <Export>
          <ExportOption
            selected={option === "op"}
            onClick={() => setOption("op")}
          >
            OP
          </ExportOption>
          {loading ? (
            <ExportButton>Loading...</ExportButton>
          ) : (
            <ExportButton onClick={() => submitChoice(id, title)}>
              Export
            </ExportButton>
          )}
        </Export>
      </Section>
    </Container>
  )
}

export default ReportChoice
