import styled from "styled-components"
import { CLR_BG2, CLR_BG3, CLR_LT1, CLR_LT2 } from "../app/constants"

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${CLR_BG3};
  border-radius: 15px;
`
const Select = styled.select`
  width: 100%;
  padding: 1rem;
  border-radius: 15px;
  border-color: ${CLR_LT1};
  font-size: 1.25rem;
  font-weight: 600;
  color: ${CLR_BG2};
  &:focus {
    outline: none;
    background-color: ${CLR_LT2};
  }
`
const Option = styled.option`
  width: 100%;
`

const MAIN_FORMS = [
  { name: "Get Order Details", value: "orderInfo" },
  { name: "Get Temp Details", value: "tempInfo" },
  { name: "Get User By Email", value: "userInfo" },
  { name: "Get Temp User By Mobile", value: "tempUserInfo" },
  { name: "Get Temps By Email", value: "tempsList" },
  { name: "Get User/s By Mobile", value: "usersByMobile" },
  { name: "Get Ticket By Id", value: "ticketInfo" },
  { name: "Get Tickets By Email", value: "ticketsByEmail" },
]

const MainFilter = ({ setFilter }) => {
  return (
    <Container>
      <Select onChange={e => setFilter(e.target.value)}>
        {MAIN_FORMS.map((form, i) => (
          <Option key={i} value={form.value}>{form.name}</Option>
        ))}
      </Select>
    </Container>
  )
}

export default MainFilter
