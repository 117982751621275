import axios from "axios"
import { API_CONNECTION, CLR_PR1 } from "../app/constants"
import { useSelector } from "react-redux"
import QuickForm from "../constants/QuickForm"

const OrderSMS = () => {
  const { currentUser } = useSelector(state => state.user)

  const submitForm = (e, value) => {
    e.preventDefault()

    axios
      .post(
        `${API_CONNECTION}/api/operations/orderSMS`,
        {
          trackId: value,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
      })
      .catch(e => alert(e.response.status))
  }

  return (
    <QuickForm
      title="Send Order SMS"
      placeholder="TrackId"
      submitForm={submitForm}
      button={{text: "Send", color: CLR_PR1}}
    />
  )
}

export default OrderSMS
