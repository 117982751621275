import { PieChart } from "@mui/x-charts/PieChart"

const CardsChart = ({ width, height }) => {
  return (
    <PieChart
      colors={['#FACC15', '#14B8A6', '#3B82F6', "#6366F1", "#8D8FE1", "#953091", "#EC4899", "#865667", "#EA3434", "#F59E0B"]}
      series={[
        {
          innerRadius: 75,
          paddingAngle: 1,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          data: [
            { id: 0, value: 10, label: "series A" },
            { id: 1, value: 15, label: "series B" },
            { id: 2, value: 20, label: "series C" },
            { id: 3, value: 20, label: "series D" },
            { id: 4, value: 20, label: "series E" },
          ],
        },
      ]}
      width={width}
      height={height}
    />
  )
}

export default CardsChart
