import styled from "styled-components"
import UsersList from "../components/UsersList"
import { useSelector } from "react-redux"

const Container = styled.div`
  width: 100%;
`

const UsersPage = () => {
  const { currentUser } = useSelector(state => state.user)

  if (currentUser.access === "marketing" || currentUser.access === "support" || currentUser.access === "blog") return <></>
  return (
    <Container>
      <UsersList />
    </Container>
  )
}

export default UsersPage
