import { useSelector } from "react-redux"
import LoginPage from "./pages/LoginPage"
import App from "./App"

const Main = () => {
  const { currentUser } = useSelector(state => state.user)

  if (!currentUser) return <LoginPage />
  return <App />
}

export default Main
