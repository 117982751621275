import styled from "styled-components"
import { CLR_BG3, CLR_LT2 } from "../app/constants"

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  overflow-x: auto;
`
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
`
const Row = styled.tr`
  background-color: ${props => props.i % 2 === 0 ? "white" : CLR_LT2};
`
const Header = styled.th`
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  padding: 1rem;
`
const Cell = styled.td`
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  padding: 1rem;
`

const ListView = ({ data }) => {
  if (!data) return <></>
  return (
    <Container>
      <Table>
        <Row i={0}>
          {Object.keys(data[0]).map((key, i) => (
            <Header key={i}>{key}</Header>
          ))}
        </Row>
        {data.map((row, i) => (
          <Row key={i} i={i}>
            {Object.keys(row).map((cell, j) => (
              <Cell key={j}>{row[cell]}</Cell>
            ))}
          </Row>
        ))}
      </Table>
    </Container>
  )
}

export default ListView
