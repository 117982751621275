import styled from "styled-components"
import { CLR_BG3, CLR_GREEN } from "../app/constants"
import { mobile } from "../app/responsive"
import { getTimeAgo } from "../app/helpers/getTimeAgo"

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`
const Section = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${CLR_GREEN};
  color: white;
  border-radius: 8px;
  ${mobile({ minWidth: "fit-content" })}
`
const Title = styled.div`
  font-size: 1.25rem;
  text-align: center;
`
const Info = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
`

const TicketsHeader = ({ data }) => {
  if (!data) return <></>
  return (
    <Container>
      <Section>
        <Title>Oldest Ticket</Title>
        <Info>{getTimeAgo(data[0].createdAt)}</Info>
      </Section>
      <Section>
        <Title>Web Tickets</Title>
        <Info>{data.filter(el => el.mode === "web").length}</Info>
      </Section>
      <Section>
        <Title>Mobile Tickets</Title>
        <Info>{data.filter(el => el.mode === "mobile").length}</Info>
      </Section>
    </Container>
  )
}

export default TicketsHeader
