import styled from "styled-components";
import { API_CONNECTION, CLR_BG3, CLR_LT1, CLR_PR1 } from "../app/constants";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ExportOptionsModal from "./ExportOptionsModal";

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  overflow-x: auto;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;
const DateWrapper = styled.div`
  padding: 1rem;
  border-radius: 15px;
  border-color: ${CLR_LT1};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
const Button = styled.button`
  width: ${(props) => props.width || "15%"};
  min-width: fit-content;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  font-size: 1rem;
  border-radius: 15px;
  font-weight: 600;
`;

const ReportsHeader = ({ setData, submitChoice }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    if (!from || !to) return alert("empty date feild");
    if (to - from > 2629746000) return alert("invalid date range");
    setData(null);
    setLoading(true);
    axios
      .post(
        `${API_CONNECTION}/api/getters/orders/range`,
        {
          f: from,
          t: to,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => alert(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Container>
        <Wrapper>
          <DateWrapper>
            <Title>F: </Title>
            <DateTimePicker
              onChange={(v) => setFrom(v)}
              showTimeSelect
              dateFormat="Pp"
            />
          </DateWrapper>
          <DateWrapper>
            <Title>T: </Title>
            <DateTimePicker
              onChange={(v) => setTo(v)}
              showTimeSelect
              dateFormat="Pp"
            />
          </DateWrapper>
        </Wrapper>
        {loading ? <>loading...</> : <Button onClick={onSubmit}>Submit</Button>}
      </Container>
    </>
  );
};

export default ReportsHeader;
