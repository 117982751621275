import styled from "styled-components"
import { CLR_BG3, CLR_GREEN } from "../app/constants"
import { mobile } from "../app/responsive"

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
`
const Section = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${CLR_GREEN};
  color: white;
  border-radius: 8px;
  ${mobile({ minWidth: "fit-content" })}
`
const Title = styled.div`
  font-size: 1.25rem;
  text-align: center;
`
const Info = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
`

const SalesTotals = ({ data }) => {
  if (!data) return <></>
  return (
    <Container>
      <Section>
        <Title>Total Cards</Title>
        <Info>{data.cards}</Info>
      </Section>
      <Section>
        <Title>Total Sales</Title>
        <Info>{data.sale.toFixed(2)} SAR</Info>
      </Section>
      <Section>
        {/* <Title>Total Expenses</Title> */}
        {/* <Info>{data.buy.toFixed(2)} SAR</Info> */}
      </Section>
      <Section>
        {/* <Title>Profit</Title> */}
        {/* <Info>{(data.sale - data.buy).toFixed(2)} SAR</Info> */}
      </Section>
      <Section>
        {/* <Title>Profit Rate</Title> */}
        {/* <Info>{(((data.sale - data.buy) / data.buy) * 100).toFixed(2)}%</Info> */}
      </Section>
    </Container>
  )
}

export default SalesTotals
