import styled from "styled-components"
import { CLR_BG3 } from "../app/constants"
import TicketBody from "./TicketBody"

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TicketsList = ({ tickets }) => {
  return (
    <Container>
      {tickets.map((el, i) => (
        <TicketBody key={i} ticket={el}/>
      ))}
    </Container>
  )
}

export default TicketsList
