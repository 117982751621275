import styled from "styled-components"
import { useSelector } from "react-redux"
import ArticlesForm from "../components/ArticlesForm"
import BlogHeader from "../components/BlogHeader"
import { useState } from "react"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const BlogPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [article, setArticle] = useState(null)

  if (currentUser.access === "support") return <></>
  return (
    <Container dir="rtl">
      <BlogHeader setArticle={setArticle} />
      <ArticlesForm article={article} />
    </Container>
  )
}

export default BlogPage
