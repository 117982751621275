import { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import {
  API_CONNECTION,
  CLR_BG1,
  CLR_BG3,
  CLR_GREEN,
  CLR_LT1,
} from "../app/constants"
import { useSelector } from "react-redux"
import { useEffect } from "react"

const FIELDS = [
  { name: "title", label: "العنوان", placeholder: "العنوان", type: "input" },
  { name: "desc", label: "الوصف", placeholder: "الوصف", type: "input" },
  { name: "body", label: "الموضوع", placeholder: "سطر 1\nسطر 2\nسطر 3", type: "textarea" },
  { name: "image", label: "الصورة", placeholder: "الصورة", type: "input" },
  { name: "genre", label: "الفئة", placeholder: "الفئة", type: "input" },
  { name: "date", label: "التاريخ", placeholder: "التاريخ", type: "input" },
  { name: "url", label: "الرابط (meta)", placeholder: "الرابط", type: "input" },
  { name: "metaTitle", label: "العنوان (meta)", placeholder: "العنوان", type: "input" },
  { name: "metaDesc", label: "الوصف (meta)", placeholder: "الوصف", type: "input" },
  { name: "metaKeys", label: "المفاتيح (meta)", placeholder: "مفتاح 1,مفتاح 2,مفتاح 3", type: "input" },
]

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${CLR_BG3};
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${CLR_BG1};
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const Label = styled.label`
  font-weight: 600;
  color: ${CLR_BG1};
`
const Input = styled.input`
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${CLR_LT1};
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 1px solid ${CLR_GREEN} !important;
  }
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${CLR_LT1};
  font-size: 1rem;
  outline: none;
  resize: vertical;
  transition: all 0.2s ease;
  height: 7rem;
  &:focus {
    border: 1px solid ${CLR_GREEN} !important;
  }
`
const Button = styled.button`
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: ${CLR_GREEN};
  color: white;
  border-radius: 8px;
`

const ArticlesForm = ({ article }) => {
  const { currentUser } = useSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    title: "",
    desc: "",
    body: "",
    image: "",
    genre: "",
    date: "",
    url: "",
    metaTitle: "",
    metaDesc: "",
    metaKeys: "",
  })

  useEffect(() => {
    if (!article) return
    setData({
      title: article.title,
      desc: article.desc,
      body: article.body,
      image: article.image,
      genre: article.genre,
      date: article.date,
      url: article.url,
      metaTitle: article.metaTitle,
      metaDesc: article.metaDesc,
      metaKeys: article.metaKeys,
    })
  }, [article])

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value })
  }

  const submitAdd = e => {
    e.preventDefault()

    setLoading(true)
    axios
      .post(`${API_CONNECTION}/api/setters/article/add`, data, {
        headers: { authorization: `Bearer ${currentUser.token}` },
      })
      .then(res => {
        alert(res.data)
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  const submitDelete = () => {
    axios
      .put(
        `${API_CONNECTION}/api/setters/article/delete`,
        { id: article.id },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
        window.location.reload()
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  const submitUpdate = e => {
    e.preventDefault()

    setLoading(true)
    axios
      .put(
        `${API_CONNECTION}/api/setters/article/edit`,
        { id: article.id, data: data },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        alert(res.data)
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Title>{article ? "تحديث" : "إضافة"} مقالة</Title>
      <Form onSubmit={article ? submitUpdate : submitAdd}>
        {FIELDS.map((el, i) => {
          if (el.type === "textarea")
            return (
              <InputWrapper key={i}>
                <Label>{el.label}</Label>
                <TextArea
                  required
                  placeholder={el.placeholder}
                  type="text"
                  onChange={e => {
                    handleChange(el.name, e.target.value)
                  }}
                  value={data[el.name]}
                />
              </InputWrapper>
            )
          return (
            <InputWrapper key={i}>
              <Label>{el.label}</Label>
              <Input
                required
                placeholder={el.placeholder}
                type="text"
                onChange={e => {
                  handleChange(el.name, e.target.value)
                }}
                value={data[el.name]}
              />
            </InputWrapper>
          )
        })}
        {loading ? (
          <div>تحميل...</div>
        ) : (
          <Button type="submit">{article ? "تحديث" : "إضافة"}</Button>
        )}
      </Form>
      {article && (
        <Button style={{ background: "tomato" }} onDoubleClick={submitDelete}>
          حذف
        </Button>
      )}
    </Container>
  )
}

export default ArticlesForm
