import styled from "styled-components"
import SalesList from "../components/SalesList"
import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import SalesHeader from "../components/SalesHeader"
import SalesTotals from "../components/SalesTotals"
import { API_CONNECTION, PRODUCTS, PRODUCTS_PRICES } from "../app/constants"
import axios from "axios"

const generateSalesRequests = orders => {
  const productsDict = {}
  let totalBuy = 0
  let totalSale = 0
  let totalCards = 0
  orders.forEach(order => {
    order.products.forEach(product => {
      if (productsDict[product.id]) productsDict[product.id]++
      else productsDict[product.id] = 1
      let productPrice = PRODUCTS_PRICES[product.id]
      totalSale += parseFloat(product.price) ? parseFloat(product.price) : 0
      totalBuy += productPrice ? parseFloat(productPrice.posPrice) : 0
      totalCards++
    })
  })
  return {
    dict: productsDict,
    totals: { sale: totalSale, buy: totalBuy, cards: totalCards },
  }
}

const formatDataToList = data => {
  let finalArr = []
  Object.keys(data).forEach(key => {
    let localProduct = PRODUCTS.find(el => el.id === key)
    if (!localProduct) return console.log(key)
    let temp = {
      id: key,
      'Service Name': localProduct.serviceName,
      Name: localProduct.name,
      "POS Price": localProduct.posPrice,
      "Sale Price": localProduct.discountPrice || localProduct.salePrice,
      Quantity: data[key],
      "Total Price": (
        (localProduct.discountPrice || localProduct.salePrice) * data[key]
      ).toFixed(2),
      "Total Profit": (((localProduct.discountPrice || localProduct.salePrice) * data[key]) - (localProduct.posPrice * data[key])).toFixed(2)
    }
    finalArr.push(temp)
  })
  return finalArr.sort((a, b) => b.Quantity - a.Quantity)
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const SalesPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [filter, setFilter] = useState("day")
  const [Loading, setLoading] = useState(false)
  const [productsData, setProductsData] = useState(null)
  const [totals, setTotals] = useState(null)
  const [timeRange, setTimeRange] = useState({
    from: null,
    to: null,
    initiated: false,
  })
  const handleTimeSelection = (point, val) => {
    setTimeRange(prev => ({ ...prev, [point]: val }))
  }
  const requestSalesData = () => {
    setLoading(true)
    setProductsData(null)
    setTotals(null)
    axios
      .post(
        `${API_CONNECTION}/api/getters/orders/list`,
        {
          filter: filter,
          timeRange,
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        const { dict, totals } = generateSalesRequests(res.data)
        setTotals(totals)
        setProductsData(formatDataToList(dict))
      })
      .catch(e => alert(e.response.status))
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (currentUser.access === "support" || currentUser.access === "blog") return
    requestSalesData()
  }, [currentUser, filter])

  if (currentUser.access === "support" || currentUser.access === "blog") return <></>
  if (Loading) return <div>Loading...</div>
  return (
    <Container>
      <SalesHeader
        filter={filter}
        setFilter={setFilter}
        timeRange={timeRange}
        data={productsData}
        handleTimeSelection={handleTimeSelection}
        requestSalesData={requestSalesData}
      />
      <SalesTotals data={totals} />
      <SalesList data={productsData} />
    </Container>
  )
}

export default SalesPage
