import QuickForm from "../constants/QuickForm"
import { CLR_PR1 } from "../app/constants"

const GetInvoice = () => {
  const submitForm = (e, value) => {
    e.preventDefault()

    window.open(
      `https://api.icard.com.sa/static/invoices/100${value}.pdf`,
      "_blank"
    )
  }

  return (
    <QuickForm
      title="Get Invoice"
      placeholder="TrackId"
      submitForm={submitForm}
      button={{ text: "Show", color: CLR_PR1 }}
    />
  )
}

export default GetInvoice
