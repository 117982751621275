import styled from "styled-components"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useState } from "react"
import axios from "axios"
import { API_CONNECTION } from "../app/constants"
import TicketsHeader from "../components/TicketsHeader"
import TicketsList from "../components/TicketsList"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const TicketsPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [tickets, setTickets] = useState(null)

  useEffect(() => {
    if (currentUser.access === "marketing" || currentUser.access === "blog") return
    axios
      .get(`${API_CONNECTION}/api/getters/tickets`, {
        headers: { authorization: `Bearer ${currentUser.token}` },
      })
      .then(res => {
        setTickets(res.data)
      })
      .catch(e => alert(e.response.status))
  })

  if (currentUser.access === "marketing" || currentUser.access === "blog") return <></>
  if (!tickets) return <>Loading...</>

  if (tickets.length === 0) return <>no pending tickets (to search for a ticket go to Main Actions)</>
  return (
    <Container>
      <TicketsHeader data={tickets}/>
      <TicketsList tickets={tickets} />
    </Container>
  )
}

export default TicketsPage
