import { LineChart } from "@mui/x-charts/LineChart"
import { CLR_GREEN } from "../app/constants"

const SalesChart = ({ width, height }) => {
  return (
    <LineChart
      colors={[CLR_GREEN]}
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
        },
      ]}
      width={width}
      height={height}
    />
  )
}

export default SalesChart
