import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/global.css';
import { store, persistor } from "./app/redux/store"
import { Provider } from "react-redux"
import Main from './Main';
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>
  </React.StrictMode>
)