import styled from "styled-components"
import { API_CONNECTION, CLR_BG3, CLR_GREEN, CLR_LT1, CLR_PR1 } from "../app/constants"
import { useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${CLR_LT1};
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 1px solid ${CLR_GREEN} !important;
  }
`
const Button = styled.button`
  width: 15%;
  min-width: 90px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  font-size: 1rem;
  border-radius: 15px;
  font-weight: 600;
`

const BlogHeader = ({ setArticle }) => {
  const { currentUser } = useSelector(state => state.user)
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)

  const submit = e => {
    e.preventDefault()

    setLoading(true)
    axios
      .post(`${API_CONNECTION}/api/getters/article`, {id: id}, {
        headers: { authorization: `Bearer ${currentUser.token}` },
      })
      .then(res => {
        setArticle(res.data)
      })
      .catch(e => alert(e.response.status))
      .finally(() => setLoading(false))
  }

  return (
    <Container>
      <Input
          placeholder="مُعرف المقالة"
          type="text"
          onChange={e => {
            setId(e.target.value)
          }}
          value={id}
        />
      {loading ? <>تحميل...</> : <Button onClick={submit}>البحث</Button>}
    </Container>
  )
}

export default BlogHeader
