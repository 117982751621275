import React, { useEffect } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CardsChart from "../components/CardsChart"
import SalesChart from "../components/SalesChart"
import TransactionsChart from "../components/TransactionsChart"
import { useMediaQuery } from "react-responsive"
import useWindowDimensions from "../app/windowDimensions"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 1rem;
`

const StatisticesPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: "(max-width: 1050px)" })
  const { width } = useWindowDimensions()

  useEffect(() => {
    if (currentUser.access === "marketing") {
      navigate("/notifications")
      return
    }
  }, [currentUser.access, navigate])

  const chartWidth = isMobile ? width : 500
  const chartHeight = isMobile ? 200 : 300

  if (currentUser.access === "marketing" || currentUser.access === "support" || currentUser.access === "blog") return <></>
  return (
    <Container>
      <CardsChart width={chartWidth} height={chartHeight} />
      <SalesChart width={chartWidth} height={chartHeight} />
      <TransactionsChart width={chartWidth} height={chartHeight} />
    </Container>
  )
}

export default StatisticesPage
