import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { logoutUser } from "./app/redux/userSlice"
import { CLR_BG1, APP_LINKS, CLR_PR1 } from "../src/app/constants"
import { useMediaQuery } from "react-responsive"
import MenuIcon from "@mui/icons-material/Menu"
import SplitscreenRoundedIcon from "@mui/icons-material/SplitscreenRounded"
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded"
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import GroupRemoveRoundedIcon from "@mui/icons-material/GroupRemoveRounded"
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

const ICONS = {
  "/main-actions": <SplitscreenRoundedIcon />,
  "/quick-actions": <RocketLaunchOutlinedIcon />,
  "/statistices": <TrendingUpRoundedIcon />,
  "/sales": <PaidOutlinedIcon />,
  "/reports": <DescriptionOutlinedIcon />,
  "/tickets": <ConfirmationNumberOutlinedIcon />,
  "/banned-users": <GroupRemoveRoundedIcon />,
  "/blog": <CollectionsBookmarkIcon />,
  logout: <LogoutRoundedIcon />,
}

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Right = styled.div`
  width: 75%;
  overflow: auto;
  margin-left: 20%;
  padding: 1rem;
`
const Left = styled.div`
  width: 20%;
  height: 100vh;
  padding: 1rem;
  background-color: ${CLR_BG1};
  position: fixed;
  z-index: 1000;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
`
const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
  flex-direction: column;
  gap: 0.5rem;
  span {
    font-size: 1rem;
    font-weight: 400;
  }
`
const Sections = styled.div`
  width: 100%;
  padding-right: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const Section = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 1rem;
  align-items: center;
  gap: 1rem;

  &:hover {
    color: ${CLR_PR1};
  }
`
const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const MobileNav = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${CLR_BG1};
  position: sticky;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  color: white;
`
const MobileHeader = styled.div`
  display: flex;
  gap: 0.1rem;
  align-items: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 600;
  span {
    font-size: 1rem;
    font-weight: 400;
  }
`
const MobileMenu = styled.div`
  width: 100vw;
  height: 0rem;
  top: 60px;
  position: fixed;
  background-color: ${CLR_BG1};
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
`
const MobileMenuLink = styled.div`
  width: 100%;
  min-height: 3.5rem;
  display: flex;
  font-weight: 600;
  gap: 0.5rem;
  border-bottom: 1px solid #405a75;
  align-items: center;
  padding: 1rem;

  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
  }
`

const Root = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.user)
  const isMobile = useMediaQuery({ query: "(max-width: 1050px)" })
  const mobileMenuRef = useRef()
  const [mobileMenu, setMobileMenu] = useState(false)

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/main-actions")
    }
  }, [location, navigate])

  const logout = () => {
    dispatch(logoutUser())
  }

  const toggleMobileMenu = () => {
    if (mobileMenu) mobileMenuRef.current.style.height = "0rem"
    else
      mobileMenuRef.current.style.height = `${(APP_LINKS.length + 1) * 3.5}rem`
    setMobileMenu(!mobileMenu)
  }

  const disktop = () => (
    <Container>
      <Left>
        <Wrapper>
          <Header>
            iCard Dashboard
            <span>admin: {currentUser.username}</span>
          </Header>
          <Sections>
            {APP_LINKS.map((link, i) => (
              <Section
                key={i}
                onClick={() => {
                  navigate(link.link)
                }}
                style={
                  location.pathname === link.link ? { color: CLR_PR1 } : {}
                }
              >
                {ICONS[link.link]}
                {link.title}
              </Section>
            ))}
            <Section onClick={logout}>
              {ICONS["logout"]}
              Logout
            </Section>
          </Sections>
        </Wrapper>
      </Left>
      <Right>
        <Outlet />
      </Right>
    </Container>
  )

  const mobile = () => (
    <MobileContainer>
      <MobileNav>
        <MenuIcon
          style={{ fontSize: "22", marginLeft: "1rem" }}
          onClick={toggleMobileMenu}
        />
        <MobileHeader>
          iCard Dashboard
          <span>admin: {currentUser.username}</span>
        </MobileHeader>
        <MenuIcon
          style={{ fontSize: "22", marginRight: "1rem", visibility: "hidden" }}
        />
        <MobileMenu ref={mobileMenuRef}>
          {APP_LINKS.map((link, i) => {
            return (
              <MobileMenuLink
                key={i}
                onClick={() => {
                  navigate(link.link)
                  toggleMobileMenu()
                }}
                style={
                  location.pathname === link.link ? { color: CLR_PR1 } : {}
                }
              >
                {ICONS[link.link]}
                {link.title}
              </MobileMenuLink>
            )
          })}
          <MobileMenuLink onClick={logout}>
            {ICONS["logout"]}
            Logout
          </MobileMenuLink>
        </MobileMenu>
      </MobileNav>
      <Outlet />
    </MobileContainer>
  )

  return <Container>{isMobile ? mobile() : disktop()}</Container>
}

export default Root
