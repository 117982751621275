import styled from "styled-components"
import { useSelector } from "react-redux"
import QuickActions from "../components/QuickActions"
import NotificationsForm from "../components/NotificationsForm"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const QuickActionsPage = () => {
  const { currentUser } = useSelector(state => state.user)

  if (currentUser.access === "marketing" || currentUser.access === "blog") return <></>
  return (
    <Container>
      <QuickActions />
      <NotificationsForm />
    </Container>
  )
}

export default QuickActionsPage
