import styled from "styled-components"
import {
  CLR_BG2,
  CLR_BG3,
  CLR_GREEN,
  CLR_LT1,
  CLR_LT2,
  CLR_PR1,
} from "../app/constants"
import { useState } from "react"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { handleSalesPageReport } from "../app/helpers/customeReport"

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 968px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const Filter = styled.select`
  width: 25%;
  min-width: fit-content;
  padding: 1rem;
  border-radius: 15px;
  border-color: ${CLR_LT1};
  font-size: 1.25rem;
  font-weight: 600;
  color: ${CLR_BG2};
  &:focus {
    outline: none;
    background-color: ${CLR_LT2};
  }
`
const Option = styled.option`
  width: 100%;
`
const Export = styled.div`
  padding: 0.5rem;
  border-radius: 25px;
  border: 1px solid ${CLR_LT1};
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
`
const ExportOption = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 25px;
  background-color: ${props => (props.selected ? CLR_PR1 : "unset")};
  color: ${props => (props.selected ? "white" : "black")};
  cursor: pointer;
`
const ExportButton = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${CLR_GREEN};
  text-decoration: underline;
`
const Text = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${CLR_BG2};
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const DateWrapper = styled.div`
  padding: 1rem;
  border-radius: 15px;
  border-color: ${CLR_LT1};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 968px) {
    gap: 0.2rem;
  }
`
const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  @media (max-width: 968px) {
    display: none;
  }
`
const Button = styled.button`
  width: 15%;
  min-width: 90px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  font-size: 1rem;
  border-radius: 15px;
  font-weight: 600;
`

const SalesHeader = ({
  filter,
  setFilter,
  data,
  handleTimeSelection,
  requestSalesData,
  timeRange,
}) => {
  const [exportOption, setExportOption] = useState("sl")
  const searchWithTimeRange = () => {
    requestSalesData()
    handleTimeSelection("initiated", true)
  }
  const generateReport = () => {
    if (!data) return
    handleSalesPageReport(data)
  }

  return (
    <>
      <Container>
        <Filter onChange={e => setFilter(e.target.value)} value={filter}>
          <Option value="day">Today</Option>
          <Option value="yesterday">Yesterday</Option>
          <Option value="this-week">This Week</Option>
          <Option value="month">This Month</Option>
          <Option value="last-month">Last Month</Option>
        </Filter>
        <Wrapper>
          <Title>Or</Title>
          <DateWrapper>
            <Text>F: </Text>
            <DateTimePicker
              onChange={v => handleTimeSelection("from", v)}
              dateFormat="Pp"
              value={timeRange.from}
            />
          </DateWrapper>
          <DateWrapper>
            <Text>T: </Text>
            <DateTimePicker
              onChange={v => handleTimeSelection("to", v)}
              dateFormat="Pp"
              value={timeRange.to}
            />
          </DateWrapper>
          <Button onClick={searchWithTimeRange}>Search</Button>
        </Wrapper>

        <Export>
          <ExportOption
            selected={exportOption === "sl"}
            onClick={() => setExportOption("sl")}
          >
            SL
          </ExportOption>
          <ExportButton onClick={generateReport}>Export</ExportButton>
        </Export>
      </Container>
      {timeRange?.initiated && (
        <Container>
          <Text>
            From {timeRange.from.format("YYYY-MM-DD HH:mm:ss")} To{" "}
            {timeRange.to.format("YYYY-MM-DD HH:mm:ss")}
          </Text>
        </Container>
      )}
    </>
  )
}

export default SalesHeader
