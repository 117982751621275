import styled from "styled-components"
import { CLR_BG2, CLR_BG3, CLR_LT1 } from "../app/constants"
import { useState } from "react"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  background-color: ${CLR_BG3};
  border-radius: 15px;
`
const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid ${CLR_LT1};
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
`
const Section = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.1rem;
  gap: 1rem;
  div {
    flex: 1;
    color: ${CLR_BG2};
    font-weight: 600;
  }
  span {
    flex: 5;
    color: black;
  }
`
const ObjectWrapper = styled.div`
  padding: 1rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const ObjectSection = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.1rem;
  gap: 1rem;
  div {
    flex: 1;
    color: black;
    font-weight: 600;
  }
  span {
    flex: 5;
    color: #727272;
  }
`

const MainInfo = ({ data }) => {
  const [sIndex, setSIndex] = useState(0)
  const [bIndex, setBIndex] = useState(0)

  console.log(data)

  if (!data) return <></>
  if (Array.isArray(data))
    return (
      <Container>
        <Wrapper>
          <Section>
            <div>Object</div>
            <span>
              <select onChange={e => setBIndex(e.target.value)}>
                {[...Array(data.length).keys()].map((el, i) => (
                  <option key={i} value={el}>
                    {el + 1}
                  </option>
                ))}
              </select>
            </span>
          </Section>
          {Object.keys(data[bIndex]).map((key, i) => {
            let dataField = data[bIndex][key]
            if (Array.isArray(dataField)) {
              return (
                <>
                  <Section>
                    <div>{key}</div>
                    <span>
                      <select onChange={e => setSIndex(e.target.value)}>
                        {[...Array(dataField.length).keys()].map((el, i) => (
                          <option key={i} value={el}>
                            {el + 1}
                          </option>
                        ))}
                      </select>
                    </span>
                  </Section>
                  <ObjectWrapper>
                    {Object.keys(dataField[sIndex]).map((el, i) => (
                      <ObjectSection key={i}>
                        <div>{el}</div>
                        <span>{JSON.stringify(dataField[sIndex][el])}</span>
                      </ObjectSection>
                    ))}
                  </ObjectWrapper>
                </>
              )
            }
            return (
              <Section key={i}>
                <div>{key}</div>
                <span>{JSON.stringify(dataField)}</span>
              </Section>
            )
          })}
        </Wrapper>
      </Container>
    )
  return (
    <Container>
      <Wrapper>
        {Object.keys(data).map((key, i) => {
          let dataField = data[key]
          if (Array.isArray(dataField) && dataField.length > 0) {
            return (
              <>
                <Section>
                  <div>{key}</div>
                  <span>
                    <select onChange={e => setSIndex(e.target.value)}>
                      {[...Array(dataField.length).keys()].map((el, i) => (
                        <option key={i} value={el}>
                          {el + 1}
                        </option>
                      ))}
                    </select>
                  </span>
                </Section>
                <ObjectWrapper>
                  {Object.keys(dataField[sIndex]).map((el, i) => (
                    <ObjectSection key={i}>
                      <div>{el}</div>
                      <span>{JSON.stringify(dataField[sIndex][el])}</span>
                    </ObjectSection>
                  ))}
                </ObjectWrapper>
              </>
            )
          }
          return (
            <Section key={i}>
              <div>{key}</div>
              <span>{JSON.stringify(dataField)}</span>
            </Section>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default MainInfo
