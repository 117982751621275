import { BarChart } from "@mui/x-charts/BarChart"
import { CLR_LT_BLUE } from "../app/constants"

const TransactionsChart = ({ width, height }) => {
  return (
    <BarChart
      series={[{ color: CLR_LT_BLUE, data: [10, 22, 5, 20] }]}
      width={width}
      height={height}
    />
  )
}

export default TransactionsChart
