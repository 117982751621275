import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { PRODUCTS_PRICES } from '../constants';

const productDataExtraction = (order) => {
    let result = [];
    let productCount = {};
    let refObj = {}
    order.products.forEach((product, index) => {
        const productName = product.nameEn;
        // Setting Product Quantity in Count Obj
        if (!productCount[productName]) {
            productCount[productName] = 1;
        } else {
            productCount[productName]++;
            if (!refObj[productName]) {
                // Saving The Ref Number
                refObj[productName] = index
                // Bug
            }
        }

        let productRef = index + 1
        let count = productCount[productName];
        // If Product Repeated will retrive the prev Ref Number
        if (count > 1) {
            productRef = refObj[productName]
        }
        // After Repeated Product Ends will get the Right Ref Number Order
        if (order.products[index - 1]?.nameEn !== productName && productCount[order.products[index - 1]?.nameEn] > 1) {
            productRef = refObj[order.products[index - 1]?.nameEn] + 1
            refObj[productName] = refObj[order.products[index - 1]?.nameEn] + 1
        }

        let productObject = {
            ...product,
            trackId: `${order.trackId}-${productRef}-${count}`,
            createdAt: order.createdAt,
            total: product.price,
            payment: order.payment,
            status: order.status,
            plateform: order.trackId.length === 10 ? "App" : "Web",
            posPrice: PRODUCTS_PRICES[product.id]?.posPrice,
            profit: (+product.price - PRODUCTS_PRICES[+product.id]?.posPrice).toFixed(2)
        }
        result.push(productObject);
    });
    return result
}

export const handleCustomeReport = (data, customeOptions) => {
    let initalArr = []

    const objectOfData = {
        "STCPayApprovedWeb": [],
        "STCPayApprovedApp": [],
        "STCPayDeclinedWeb": [],
        "STCPayDeclinedApp": [],
        "CARDApprovedWeb": [],
        "CARDApprovedApp": [],
        "CARDDeclinedWeb": [],
        "CARDDeclinedApp": [],
        "ApplePayApprovedWeb": [],
        "ApplePayApprovedApp": [],
        "ApplePayDeclinedWeb": [],
        "ApplePayDeclinedApp": []
    }
    for (let i = 0; i < data.length; i++) {
        if (data[i].status && data[i].trackId.length < 10 && data[i].payment === "STCPay") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.STCPayApprovedWeb.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length < 10 && data[i].payment === "STCPay") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        } else if (data[i].status && data[i].trackId.length === 10 && data[i].payment === "STCPay") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.STCPayApprovedApp.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length === 10 && data[i].payment === "STCPay") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        } else if (data[i].status && data[i].trackId.length < 10 && data[i].payment === "CARD") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.CARDApprovedWeb.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length < 10 && data[i].payment === "CARD") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        } else if (data[i].status && data[i].trackId.length === 10 && data[i].payment === "CARD") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.CARDApprovedApp.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length === 10 && data[i].payment === "CARD") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        } else if (data[i].status && data[i].trackId.length < 10 && data[i].payment === "ApplePay") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.ApplePayApprovedWeb.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length < 10 && data[i].payment === "ApplePay") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        } else if (data[i].status && data[i].trackId.length === 10 && data[i].payment === "ApplePay") {
            let products = productDataExtraction(data[i])
            products.map((item) => {
                objectOfData.ApplePayApprovedApp.push(item)
                return null
            })
        } else if (!data[i].status && data[i].trackId.length === 10 && data[i].payment === "ApplePay") {
            objectOfData.STCPayDeclinedWeb.push({
                ...data[i], nameEn: "Declined", plateform: data[i].trackId.length === 10 ? "App" : "Web",
                serial: "Declined", secret: "Declined", profit: "Declined", posPrice: "Declined"
            })
        }
    }


    let isDeclinedRequested = customeOptions.find((item) => item === 'declined')
    let isApprovedRequested = customeOptions.find((item) => item === 'approved')
    for (let i = 0; i < customeOptions.length; i++) {
        if (customeOptions[i] === 'stc-web') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.STCPayApprovedWeb]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.STCPayDeclinedWeb]
        } else if (customeOptions[i] === 'stc-app') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.STCPayApprovedApp]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.STCPayDeclinedApp]
        } else if (customeOptions[i] === 'card-web') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.CARDApprovedWeb]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.CARDDeclinedWeb]
        } else if (customeOptions[i] === 'card-app') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.CARDApprovedApp]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.CARDDeclinedApp]
        } else if (customeOptions[i] === 'apple-web') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.ApplePayApprovedWeb]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.ApplePayDeclinedWeb]
        } else if (customeOptions[i] === 'apple-app') {
            if (isApprovedRequested) initalArr = [...initalArr, ...objectOfData.ApplePayApprovedWeb]
            if (isDeclinedRequested) initalArr = [...initalArr, ...objectOfData.ApplePayDeclinedApp]
        }
    }
    return initalArr
}


export const sortBasedOnStringKey = (data, key) => {
    if (!data || !key) return
    return data.sort((a, b) => {
        if (a[`${key}`] < b[`${key}`]) {
            return -1;
        }
        if (a[`${key}`] > b[`${key}`]) {
            return 1;
        }
        return 0;
    });
}
export async function handleDownloadExcel_(header, body) {
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');


    // Add headers to the worksheet
    worksheet.columns = header.map((key) => ({
        header: key,
        key: key,
        width: 20,
    }));

    // Add data rows to the worksheet
    body.forEach((item) => {
        worksheet.addRow(item);
    });
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = {
            name: 'Arial',
            size: 14,
            bold: true,
            color: { argb: 'FFFFFFF' },
            Decimal: { width: 150, height: 150 }
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F39425' }, // Set the background color (ARGB format, here it's blue)
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        // Skip the header row (row 1)

        row.eachCell({ includeEmpty: true }, (cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `report-${new Date().toISOString()}.xlsx`);
}



export const handleReportsPageReport = (data) => {
    const header = [
        "Time",
        "Product Name",
        "POS Price",
        "Sale Price",
        "Profit",
        "Payment",
        "Status",
        "Plateform",
        "Serial",
        "Secret",
        "TrackId",
    ];

    let body = data.map((item) => ({
        [header[0]]: item.createdAt,
        [header[1]]: item.nameEn,
        [header[2]]: item.posPrice === 'Declined' ? item.posPrice : `${item.posPrice} SAR`,
        [header[3]]: item.total === 'Declined' ? item.total : `${item.total} SAR`,
        [header[4]]: item.profit === 'Declined' ? item.profit : `${item.profit} SAR`,
        [header[5]]: item.payment,
        [header[6]]: item.status == 1 ? "Approved" : "Declined",
        [header[7]]: item.plateform,
        [header[8]]: item.serial,
        [header[9]]: item.secret,
        [header[10]]: item.trackId,
    }));
    handleDownloadExcel_(header, body)
}

export const handleSalesPageReport = (data) => {
    const header = [
        "Service Name",
        "Product Name",
        "POS Price",
        "Sale Price",
        "Quantity",
        "Total Price",
        "Total Profit",
    ];
    const totals = {
        quantity: 0,
        'Total Price': 0
    }
    let body = sortBasedOnStringKey(data, 'Service Name').map((item) => {
        totals.quantity += +item.Quantity
        totals["Total Price"] += +item["Total Price"]
        return {
            [header[0]]: item['Service Name'],
            [header[1]]: item.Name,
            [header[2]]: `${item['POS Price']} SAR`,
            [header[3]]: `${item['Sale Price']} SAR`,
            [header[4]]: item.Quantity,
            [header[5]]: `${item['Total Price']} SAR`,
            [header[6]]: `${item['Total Profit']} SAR`
        }
    });
    body = [...body, {
        [header[0]]: "",
        [header[1]]: "",
        [header[2]]: "",
        [header[3]]: "",
        [header[4]]: totals.quantity,
        [header[5]]: `${totals['Total Price'].toFixed(2)} SAR`,
        [header[6]]: ``
    }]
    handleDownloadExcel_(header, body)
}

