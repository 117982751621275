import { useState } from "react"
import styled from "styled-components"
import OrderInfo from "../components/OrderInfo"
import TempUserInfo from "../components/TempUserInfo"
import UserInfo from "../components/UserInfo"
import UsersByMobile from "../components/UsersByMobile"
import TempInfo from "../components/TempInfo"
import TempsList from "../components/TempsList"
import { useSelector } from "react-redux"
import MainFilter from "../components/MainFilter"
import TicketInfo from "../components/TicketInfo"
import TicketsByEmail from "../components/TicketsByEmail"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const MAIN_FORMS = {
  orderInfo: <OrderInfo />,
  tempInfo: <TempInfo />,
  tempsList: <TempsList />,
  userInfo: <UserInfo />,
  tempUserInfo: <TempUserInfo />,
  usersByMobile: <UsersByMobile />,
  ticketInfo: <TicketInfo />,
  ticketsByEmail: <TicketsByEmail />,
}

const MainActionsPage = () => {
  const { currentUser } = useSelector(state => state.user)
  const [filter, setFilter] = useState("orderInfo")

  if (currentUser.access === "marketing" || currentUser.access === "blog") return <></>
  return (
    <Container>
      <MainFilter setFilter={setFilter} />
      {MAIN_FORMS[filter]}
    </Container>
  )
}

export default MainActionsPage
