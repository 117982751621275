import axios from 'axios'
import { useEffect } from 'react'
import { API_CONNECTION } from '../app/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import ListView from "../constants/ListView"

const formatUsers = users => {
  let finalArr = []
  users.forEach(user => {
    let temp = {
      name: user.firstName + " " + user.lastName,
      email: user.email,
      mobile: user.mobile,
      createdAt: user.createdAt
    }
    finalArr.push(temp)
  });
  return finalArr
}

const UsersList = () => {
  const {currentUser} = useSelector(state => state.user)
  const [users, setUsers] = useState(null)
  
  useEffect(() => {
    axios
      .post(
        `${API_CONNECTION}/api/getters/users/list`,
        {
          filter: "banned",
        },
        {
          headers: { authorization: `Bearer ${currentUser.token}` },
        }
      )
      .then(res => {
        setUsers(formatUsers(res.data))
      })
      .catch(e => alert(e.response.status))
  }, [currentUser])

  if (users) return <ListView data={users} />
  else return <div>Loading...</div>
}

export default UsersList