import { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { API_CONNECTION, CLR_PR1 } from "../app/constants"
import { useDispatch } from "react-redux"
import { loginUser } from "../app/redux/userSlice"
import { mobile } from "../app/responsive"

const Container = styled.div`
  width: 25%;
  background-color: white;
  border-radius: 50px;
  padding: 3vw;
  display: flex;
  gap: 5vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mobile({width: "75%", gap: "1.5rem", borderRadius: "25px"})}
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
`
const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border-radius: 25px;
  border: 2px solid gainsboro;
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 2px solid ${CLR_PR1} !important;
  }
`
const Button = styled.button`
  width: 100%;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  font-size: 1rem;
  border-radius: 25px;
  font-weight: 600;
`
const Logo = styled.img`
  width: 50%;
  aspect-ratio: 1/1;
`

const LoginForm = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()

  const submitForm = e => {
    e.preventDefault()
    axios
      .post(`${API_CONNECTION}/api/users/login`, {
        username: username,
        password: password,
      })
      .then(res => {
        dispatch(loginUser(res.data))
      })
      .catch(e => alert(e.response.status))
  }

  return (
    <Container>
      <Logo src="logo.png" />
      <Form onSubmit={submitForm}>
        <Input
          required
          placeholder="Username"
          type="text"
          onChange={e => {
            setUsername(e.target.value)
          }}
          value={username}
        />
        <Input
          required
          placeholder="Password"
          type="password"
          onChange={e => {
            setPassword(e.target.value)
          }}
          value={password}
        />
        <Button type="submit">Login</Button>
      </Form>
    </Container>
  )
}

export default LoginForm
