import { useState } from "react"
import styled from "styled-components"
import { CLR_BG1, CLR_BG3, CLR_LT1, CLR_PR1 } from "../app/constants"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  background-color: ${CLR_BG3};
  border-radius: 15px;
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${CLR_BG1};
`
const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
`
const Input = styled.input`
  width: 80%;
  padding: 1.5rem;
  border-radius: 8px;
  border: 2px solid ${CLR_LT1};
  outline: none;
  transition: all 0.2s ease;
  font-size: 1rem;
  &:focus {
    border: 2px solid ${CLR_PR1} !important;
  }
`
const Button = styled.button`
  flex: 1;
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  background-color: ${CLR_PR1};
  color: white;
  border-radius: 8px;
`

const MainForm = ({ title, placeholder, submitForm }) => {
  const [value, setValue] = useState("")

  return (
    <Container>
      <Title>{title}</Title>
      <Form onSubmit={e => submitForm(e, value)}>
        <Input
          placeholder={placeholder}
          required
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  )
}

export default MainForm
